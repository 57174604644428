@tailwind base;

@layer base {
    @font-face {
        font-family: gt-super;
        src: url("./assets/fonts/gt-super-400-normal.woff");
    }

    @font-face {
        font-family: sohne;
        src: url("./assets/fonts/sohne-400-normal.woff");
    }

    @font-face {
        font-family: sohne-italic;
        src: url("./assets/fonts/sohne-400-italic.woff");
    }

    @font-face {
        font-family: sohne-bold;
        src: url("./assets/fonts/sohne-700-normal.woff");
    }

    @font-face {
        font-family: sohne-semibold;
        src: url("./assets/fonts/sohne-500-normal.woff");
    }

    @font-face {
        font-family: fell;
        src: url("./assets/fonts/fell-400-normal.woff");
    }

    @font-face {
        font-family: content-serif;
        src: url("./assets/fonts/content-serif.woff");
    }

    @font-face {
        font-family: content-sans;
        src: url("./assets/fonts/content-sans.woff");
    }

    @font-face {
        font-family: content-sans-bold;
        src: url("./assets/fonts/content-sans-bold.woff");
    }

    @font-face {
        font-family: source-serif-pro;
        src: url("./assets/fonts/source-serif-pro-400-normal.woff");
    }

    @font-face {
        font-family: source-serif-pro-bold;
        src: url("./assets/fonts/source-serif-pro-700-normal.woff");
    }

    /* Apply color to google icon */
    .fa-google {
        background: conic-gradient(
                from -45deg,
                #ea4335 110deg,
                #4285f4 90deg 180deg,
                #34a853 180deg 270deg,
                #fbbc05 270deg
            )
            73% 55%/150% 150% no-repeat;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
    }
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.ql-container.ql-snow {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.ql-editor {
    font-size: 21px;
    background-color: white;
}

.home-screen {
    max-width: 1336px;
    margin: auto;
}

.bg-thm-blue {
    background-color: #003366;
}

.bg-thm-night {
    background-color: #004d40;
}

.bg-thm-plum {
    background-color: #5D1049;
}

.bg-thm-default {
    background-color: #800020;
}

.bg-thm-common {
    padding: 7em;
}

.bg-thm-common h1,
.bg-thm-common .back_btn a {
    color: #fff;
}

.bg-thm-common .back_btn a:hover {
  text-decoration: underline;
}

.bg-thm-common p {
    color: #fff;
}

.bg-thm-common h2 {
    color: #fff;
}

.bg-thm-common h2+span {
    color: #fff;
}

.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }

    .post__content {
        word-wrap: break-word;
    }

  .dark-mode-on .post__content p,
  .dark-mode-on .post__content p > * {
    color: rgb(203 213 225) !important;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  .trending__org a {
    padding-bottom: 0 !important;
    margin-bottom: 1rem;
  }
  

@tailwind components;
@tailwind utilities;
